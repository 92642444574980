<template>
  <b-card>

    <h4 v-if="currentObject.event_name" class="col-md-6">
      Event Reports <a :href="'/events/' + currentObject.event_id + '/view'">({{ currentObject.event_name }})</a>
    </h4>
    
    <div class="row">
      <div class="col-md-6">
        <div class="float-left ml-1 mt-1">
          <b-button size="md" variant="secondary" @click="downloadExcel()" v-if="$can('view', 'event_stats')" :disabled="isLoading || isEmpty">
            <feather-icon
              icon="DownloadIcon"
              class="mr-50"
            /><span>Export Excel</span>
          </b-button>
        </div>
      </div>
    </div>

    <!-- table -->
    <div v-if="isLoading || !currentObject.event_name" class="text-center">
        <b-spinner label="Tunggu..."></b-spinner>
    </div>
    <div v-if="!isLoading && isEmpty" class="text-center">
        <p>The participant's still empty</p>
    </div>
    <div class="event-stats" v-if="!isEmpty && !isLoading">
      <div class="ml-1 mt-1 row">
        <div class="col-md-12 mb-2 text-center" v-if="formattedData.main !== null">
          <h5 class="mb-2 mt-1">Overview</h5>
          <event-stats-chart
            :height="350"
            :data="formattedData.main"
            v-if="formattedData.main !== null"
            :options="chartOptions"
          />
        </div>

        <div class="col-md-12 mb-2 text-center" v-if="formattedData.companies !== null">
          <h5 class="mb-2 mt-1">Companies</h5>
          <event-stats-chart
            :height="350"
            :data="formattedData.companies"
            v-if="formattedData.companies !== null"
            :options="chartOptions"
          />
        </div>

        <div class="col-md-12 mb-2 text-center" v-if="formattedData.company_sizes !== null">
          <h5 class="mb-2 mt-1">Company Sizes</h5>
          <event-stats-chart
            :height="350"
            :data="formattedData.company_sizes"
            v-if="formattedData.company_sizes !== null"
            :options="chartOptions"
          />
        </div>

        <div class="col-md-12 mb-2 text-center" v-if="formattedData.job_levels !== null">
          <h5 class="mb-2 mt-1">Job Levels</h5>
          <event-stats-chart
            :height="350"
            :data="formattedData.job_levels"
            v-if="formattedData.job_levels !== null"
            :options="chartOptions"
          />
        </div>

        <div class="col-md-12 mb-2 text-center" v-if="formattedData.positions !== null">
          <h5 class="mb-2 mt-1">Job Titles</h5>
          <event-stats-chart
            :height="350"
            :data="formattedData.positions"
            v-if="formattedData.positions !== null"
            :options="chartOptions"
          />
        </div>

        <div class="col-md-12 mb-2 text-center" v-if="formattedData.industries !== null">
          <h5 class="mb-2 mt-1">Industries</h5>
          <event-stats-chart
            :height="350"
            :data="formattedData.industries"
            v-if="formattedData.industries !== null"
            :options="chartOptions"
          />
        </div>
      </div>
    </div>

    <div class="mt-2">
      <div style="float: left">
        <b-button size="md" variant="outline-primary" @click="viewAllEvents()">
          <feather-icon
            icon="ArrowLeftIcon"
            class="mr-50"
          /><span>Kembali</span>
        </b-button>
      </div>
    </div>
  </b-card>

</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BForm, BButton, BSpinner, BRow, BCol
} from 'bootstrap-vue'
import router from '@/router'
import { $themeColors } from '@themeConfig'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import * as XLSX from 'xlsx'
import EventStatsChart from './EventStatsChart.vue'

const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BForm,
    BButton,
    BSpinner,
    vSelect,
    BRow,
    BCol,
    XLSX,
    EventStatsChart,
  },
  data() {
    return {
      isEmpty: false,
      chartOptions: {
        elements: {
        rectangle: {
          borderWidth: 2,
          borderSkipped: 'bottom',
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      responsiveAnimationDuration: 500,
      legend: {
        display: false,
      },
      tooltips: {
        // Updated default tooltip UI
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowBlur: 8,
        shadowColor: chartColors.tooltipShadow,
        backgroundColor: $themeColors.light,
        titleFontColor: $themeColors.dark,
        bodyFontColor: $themeColors.dark,
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              display: true,
              color: chartColors.grid_line_color,
              zeroLineColor: chartColors.grid_line_color,
            },
            scaleLabel: {
              display: false,
            },
            ticks: {
              fontColor: chartColors.labelColor,
            },
          },
        ],
        yAxes: [
          {
            display: true,
            gridLines: {
              color: chartColors.grid_line_color,
              zeroLineColor: chartColors.grid_line_color,
            },
            ticks: {
              stepSize: 100,
              min: 0,
              // max: 400,
              fontColor: chartColors.labelColor,
            },
          },
        ],
        },
      },
      isLoading: false,
      formattedData: {
        main: null,
        companies: null, 
        company_cities: null, 
        company_sizes: null,
        industries: null,
        company_cities: null,
        job_levels: null,
        positions: null
      },
      data: {
        main: {
          labels: [],
          data: []
        },
        companies: {
          labels: [],
          data: []
        },
        company_cities: {
          labels: [],
          data: []
        },
        positions: {
          labels: [],
          data: []
        },
        job_levels: {
          labels: [],
          data: []
        },
        company_sizes: {
          labels: [],
          data: []
        },
        industries: {
          labels: [],
          data: []
        }
      },
      pageName: "Event Reports",
      currentObject: {
        id: '',
        name: '',
        event_id: -1,
        event_name: null,
        visits: []
      },
    }
  },
  computed: {
  },
  methods: {
    generateNumber() {
      return Math.floor(Math.random() * (235 - 52 + 1) + 52)
    },
    generateColor() {
      return 'rgb(' + this.generateNumber() + ', ' + this.generateNumber() + ', ' + this.generateNumber() + ')'
    },
    formatData(inputData) {
      let borderColors = []
      let backgroundColors = []
      let labels = []
      let dataset = []

      inputData.labels.forEach((item, index) => {
        labels.push(item)

        const r = this.generateNumber()
        const g = this.generateNumber()
        const b = this.generateNumber()

        borderColors.push("rgba(" + r + ", " + g + ", " + b + ", 1)")
        backgroundColors.push("rgba(" + r + ", " + g + ", " + b + ", 0.2)")
      })

      inputData.data.forEach((item, index) => {
        dataset.push(item)
      })

      return {
        labels: labels,
        datasets: [
          {
            // label: "Total",
            backgroundColor: backgroundColors,
            borderColor: borderColors,
            data: dataset,
            borderWidth: 1
          }
        ]
      }
    },
    downloadExcel() {
      this.isLoading = true

      const workbook = XLSX.utils.book_new()
      
      let excelRows = []

      // Overview
      this.data.main.data.forEach((item, index) => {
        excelRows.push({name: this.data.main.labels[index], total: item })
      })

      let worksheet = XLSX.utils.json_to_sheet(excelRows)

      XLSX.utils.sheet_add_aoa(worksheet, [["Name", "Total"]], { origin: "A1" });

      XLSX.utils.book_append_sheet(workbook, worksheet, "Overview")

      // Companies
      excelRows = []

      this.data.companies.data.forEach((item, index) => {
        excelRows.push({
            name: this.data.companies.labels[index], 
            total: item 
          })
      })

      worksheet = XLSX.utils.json_to_sheet(excelRows)

      XLSX.utils.sheet_add_aoa(worksheet, [["Name", "Total"]], { origin: "A1" });

      XLSX.utils.book_append_sheet(workbook, worksheet, "Companies")

      // Company sizes
      excelRows = []

      this.data.company_sizes.data.forEach((item, index) => {
        excelRows.push({name: this.data.company_sizes.labels[index], total: item })
      })

      worksheet = XLSX.utils.json_to_sheet(excelRows)

      XLSX.utils.sheet_add_aoa(worksheet, [["Name", "Total"]], { origin: "A1" });

      XLSX.utils.book_append_sheet(workbook, worksheet, "Company Sizes")

      // Industries
      excelRows = []

      this.data.industries.data.forEach((item, index) => {
        excelRows.push({name: this.data.industries.labels[index], total: item })
      })

      worksheet = XLSX.utils.json_to_sheet(excelRows)

      XLSX.utils.sheet_add_aoa(worksheet, [["Name", "Total"]], { origin: "A1" });

      XLSX.utils.book_append_sheet(workbook, worksheet, "Industries")

      // Job Titles
      excelRows = []

      this.data.positions.data.forEach((item, index) => {
        excelRows.push({name: this.data.positions.labels[index], total: item })
      })

      worksheet = XLSX.utils.json_to_sheet(excelRows)

      XLSX.utils.sheet_add_aoa(worksheet, [["Name", "Total"]], { origin: "A1" });

      XLSX.utils.book_append_sheet(workbook, worksheet, "Job Titles")

      // Job Levels
      excelRows = []

      this.data.job_levels.data.forEach((item, index) => {
        excelRows.push({name: this.data.job_levels.labels[index], total: item })
      })

      worksheet = XLSX.utils.json_to_sheet(excelRows)

      XLSX.utils.sheet_add_aoa(worksheet, [["Name", "Total"]], { origin: "A1" });

      XLSX.utils.book_append_sheet(workbook, worksheet, "Job Levels")

      // Company Cities
      excelRows = []

      this.data.company_cities.data.forEach((item, index) => {
        excelRows.push({name: this.data.company_cities.labels[index], total: item })
      })

      worksheet = XLSX.utils.json_to_sheet(excelRows)

      XLSX.utils.sheet_add_aoa(worksheet, [["Name", "Total"]], { origin: "A1" });

      XLSX.utils.book_append_sheet(workbook, worksheet, "Company Cities")

      XLSX.writeFile(workbook, this.pageName + "_" + Date.now() + ".xlsx", { compression: true })

      this.isLoading = false
    },
    viewAllEvents() {
      this.$router.push('/events')
    },
    fetchEvent() {
      const id = router.currentRoute.params.id

      this.$http.get('/admin/v1/events/' + id)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.currentObject.event_name =  currentData.name
          }
     })
    },
    fetchData() {
      this.isLoading = true

      const id = router.currentRoute.params.id

      this.currentObject.event_id = id

      this.$http.get('/admin/v1/events/' + this.currentObject.event_id + '/stats')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            if(currentData === null) {
              this.isEmpty = true
            }
            else {
              this.data.main.labels = currentData.main.labels
              this.data.main.data = currentData.main.data

              this.formattedData.main = this.formatData(this.data.main)

              if(currentData.companies === undefined) {
                this.data.companies.labels = []
                this.data.companies.data = []
              }
              else {
                this.data.companies.labels = currentData.companies.labels
                this.data.companies.data = currentData.companies.data
              }

              this.formattedData.companies = this.formatData(this.data.companies)

              if(currentData.company_cities === undefined) {
                this.data.company_cities.labels = []
                this.data.company_cities.data = []
              }
              else {
                this.data.company_cities.labels = currentData.company_cities.labels
                this.data.company_cities.data = currentData.company_cities.data
              }

              this.formattedData.company_cities = this.formatData(this.data.company_cities)

              if(currentData.positions === undefined) {
                this.data.positions.labels = []
                this.data.positions.data = []
              }
              else {
                this.data.positions.labels = currentData.positions.labels
                this.data.positions.data = currentData.positions.data
              }

              this.formattedData.positions = this.formatData(this.data.positions)

              if(currentData.job_levels === undefined) {
                this.data.job_levels.labels = []
                this.data.job_levels.data = []
              }
              else {
                this.data.job_levels.labels = currentData.job_levels.labels
                this.data.job_levels.data = currentData.job_levels.data
              }

              this.formattedData.job_levels = this.formatData(this.data.job_levels)

              if(currentData.company_sizes === undefined) {
                this.data.company_sizes.labels = []
                this.data.company_sizes.data = []
              }
              else {
                this.data.company_sizes.labels = currentData.company_sizes.labels
                this.data.company_sizes.data = currentData.company_sizes.data
              }

              this.formattedData.company_sizes = this.formatData(this.data.company_sizes)

              if(currentData.industries === undefined) {
                this.data.industries.labels = []
                this.data.industries.data = []
              }
              else {
                this.data.industries.labels = currentData.industries.labels
                this.data.industries.data = currentData.industries.data
              }

              this.formattedData.industries = this.formatData(this.data.industries)
            }
          }

          this.isLoading = false

     })
    },
  },
  created() {
    this.fetchData()
    this.fetchEvent()
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
